<template>
  <NuxtLayout>
    <main class="max-w-full py-2 mx-auto text-center [&>*]:py-4">
      <h1>It seems something has gone wrong...</h1>
      <NuxtLink class="btn btn-secondary block mx-auto" @click="goBack">Back to Safety</NuxtLink>

      <div>
        <RippleUiCollapse class="overflow-x-scroll" title="Technical details">
          <pre class="text-start">{{ error }}</pre>
        </RippleUiCollapse>

      </div>
    </main>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import type {NuxtError} from '#app'

const router = useRouter();
const goBack = () => {
  if (window.history.length > 1) {
    router.go(-1);
  } else {
    router.push('/');
  }
}

const props = defineProps({
  error: Object as () => NuxtError
})
</script>
