<template>
  <div
      :class="{'card-horizontal': horizontal }"
      class="card card-transparent">
    <div
        :class="horizontal?'w-64':'w-full h-32'"
    >
      <NuxtPicture
          :alt="post['headerAlt']"
          :img-attrs="{'class':'absolute w-full h-full object-cover'}"
          :src="'/img/content/'+post['headerImg']"
          class="block w-full h-full relative"
          placeholder
      />
    </div>
    <div class="card-body flex-grow">
      <h3 class="text-lg">{{ post.title }}</h3>
      <p class="flex-grow">
        {{ post['description'] }}
      </p>

      <div class="flex justify-between w-full">
        <NuxtLink
            :to="getUrl(post)"
            class="btn light:btn-secondary dark:btn-outline-secondary"
        >
          Read More &rarr;
        </NuxtLink>

        <span class="text-xs text-black/50 dark:text-white/50 tabular-nums text-right">
          <span v-if="post.updatedAt != post.createdAt">
            Updated: <FmtDateTime :date="post.updatedAt"/>
            <br/>
          </span>
            Created: <FmtDateTime :date="post.createdAt"/>
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import {FmtDateTime} from "#components";
import {DateTime} from "luxon";
import type {PropType} from "vue";
import type {Post} from "~/lib/vars";

type Article = Omit<Post, 'body'>

function getUrl(post: Article) {
  return post._path.replace('_', '/');
}

const formatDate = (date: string) => {
  let d = DateTime.fromISO(date);

  if (d.diffNow('days').days > -1) {
    return "Today";
  }
  if (d.diffNow('days').days > -7) {
    return d.toRelative();
  }

  h(FmtDateTime, {date, format: 'long'});
};

const props = defineProps({
  horizontal: {
    type: Boolean,
    default: false
  },
  post: {
    type: Object as PropType<Article>,
    required: true
  }
});
</script>
<style>
.card-horizontal {
  @apply flex flex-row items-stretch;
}
</style>
