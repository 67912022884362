import type {Ref, WatchCallback} from "vue"
import {onMounted, ref, watch} from "vue";
import {breakpointsTailwind, useBreakpoints as useBreakpointsVU} from '@vueuse/core';

export function useDebugColorMode() {
    const route = useRoute();
    const colorMode = useColorMode();
    watch(() => route.query, () => {
        if (!route.query.color) return;
        colorMode.preference = route.query.color.toString();
    }, {immediate: true});
}

export const useBreakpoints = useBreakpointsVU(breakpointsTailwind);

export function useDetectHeading({root, header, onHeadingDetected}: {
    root: Ref<HTMLElement>,
    header: Ref<HTMLElement | null>,
    onHeadingDetected?: WatchCallback<null | HTMLHeadingElement>
}) {
    let heading = ref<null | HTMLHeadingElement>(null);
    let observer = ref<null | IntersectionObserver>(null);

    onMounted(() => {
        observer.value = new IntersectionObserver((entries) => {
            const firstIntersection = entries.find((entry) => entry.isIntersecting);

            if (!firstIntersection || !firstIntersection.target) return;

            let current = firstIntersection.target;
            while (true) {
                if (['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(current.tagName)) {
                    heading.value = current as HTMLHeadingElement;
                    break;
                }

                if (!root.value.contains(current)) {
                    heading.value = null;
                    break;
                }

                if (current.previousElementSibling) {
                    current = current.previousElementSibling;
                } else if (current.parentElement) {
                    current = current.parentElement;
                } else {
                    break;
                }
            }
        }, {rootMargin: "-15% 0px -70% 0px"});

        return () => observer.value?.disconnect();
    });

    watch(root, (newRoot) => {
        observer.value?.disconnect();

        if (!newRoot) return;
        observer.value?.observe(newRoot);
        newRoot.querySelectorAll('p').forEach((p) => observer.value?.observe(p));
    }, {immediate: true});

    watch(heading, (heading, oldValue, onCleanup) => {
        if (!onHeadingDetected) return;
        onHeadingDetected(heading, oldValue, onCleanup);
    }, {immediate: true});

    return {heading, observer};
}

